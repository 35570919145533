import { createGlobalStyle } from 'styled-components';

const ColorInverter = createGlobalStyle`
  body.contrast-class {
    background-color: #000;

    div,
    header,
    footer {
      background-image: none;
      background-color: black !important;
      color: white !important;
    }

    div:before,
    div:after {
      border-bottom-color: #000;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    label,
    strong,
    em,
    cite,
    q,
    i,
    b,
    u,
    span,
    legend {
      color: white !important;
    }

    nav,
    li,
    ol,
    footer,
    section,
    main,
    aside,
    article {
      background-color: transparent !important;
      color: white !important;
    }


    input[type=text],
    input[type=password],
    input[type=url],
    input[type=search],
    input[type=email],
    input[type=tel],
    input[type=date],
    input[type=month],
    input[type=week],
    input[type=datetime],
    input[type=datetime-local],
    textarea,
    input[type=number] {
      background-color: black !important;
      border: 1px solid yellow !important;
      color: white !important;
    }


    a {
      color: yellow !important;
    }


    button,
    button > span,
    input[type=button],
    input[type=reset],
    input[type=submit] {
      background-color: black !important;
      color: yellow !important;
      border: none !important;
    }

    button > svg,
    a > svg {
      fill: yellow !important;
    }

    button.btn,
    input[type=button].btn,
    input[type=reset].btn,
    input[type=submit].btn {
      background-color: yellow !important;
      color: black !important;
      border: none !important;
    }
  }
`;

export default ColorInverter;
