const theme = {
  palette: {
    primary: '#23507a',
    secondary: '#e91d2b',
    text: '#4a4a4a',
    lightBlue: '#05aada',
    button: '#58a20b',
    white: '#ffffff',
    black: '#000000',
    blue: '#004691',
    red: '#eb0028',
  },
};

export default theme;
