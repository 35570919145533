import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import Svgs from '../shared/components/svgs';
import Fonts from '../shared/styles/fonts';
import ColorInverter from '../shared/styles/color-inverter';
import theme from '../shared/styles/theme';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet title={data.site.siteMetadata.title}>
          <html lang="en" />
        </Helmet>
        <Svgs />
        <Fonts />
        <ColorInverter />
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <main>{children}</main>
        </ThemeProvider>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body {
    overflow-x: hidden;
  }

  body {
    font-family: 'Roboto', sans-serif;
  }

  #___gatsby ~ *:not(#cdModal) {
    display: none;
  }
  ._hj-widget-container {
    display: block !important;
  }

  ul {
    list-style: none;
  }

`;

export default Layout;
