// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-bb-index-js": () => import("./../../../src/pages/bb/index.js" /* webpackChunkName: "component---src-pages-bb-index-js" */),
  "component---src-pages-carroconectado-index-js": () => import("./../../../src/pages/carroconectado/index.js" /* webpackChunkName: "component---src-pages-carroconectado-index-js" */),
  "component---src-pages-express-index-js": () => import("./../../../src/pages/express/index.js" /* webpackChunkName: "component---src-pages-express-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-light-index-js": () => import("./../../../src/pages/light/index.js" /* webpackChunkName: "component---src-pages-light-index-js" */),
  "component---src-pages-light-vendedor-index-js": () => import("./../../../src/pages/light-vendedor/index.js" /* webpackChunkName: "component---src-pages-light-vendedor-index-js" */),
  "component---src-pages-timblackfamilia-index-js": () => import("./../../../src/pages/timblackfamilia/index.js" /* webpackChunkName: "component---src-pages-timblackfamilia-index-js" */),
  "component---src-pages-timblackfamilia-vendedor-index-js": () => import("./../../../src/pages/timblackfamilia-vendedor/index.js" /* webpackChunkName: "component---src-pages-timblackfamilia-vendedor-index-js" */),
  "component---src-pages-vendedor-index-js": () => import("./../../../src/pages/vendedor/index.js" /* webpackChunkName: "component---src-pages-vendedor-index-js" */)
}

