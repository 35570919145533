/* eslint-disable max-len */
import { createGlobalStyle } from 'styled-components';
import TIMSansWebRegularWoff from '../../fonts/TIMSansWeb-Regular.woff';
import TIMSansWebRegularWoff2 from '../../fonts/TIMSansWeb-Regular.woff2';

const Fonts = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');

  @font-face {
   font-family: 'TIMSans';
   src: url(${TIMSansWebRegularWoff2}) format('woff2');
   src: url(${TIMSansWebRegularWoff}) format('woff');
  }
  
`;

export default Fonts;
